import styled from 'styled-components';

import Typography from '../../styles/Typography';
import media from '../../../common/MediaQueries';

export const TextList = styled.ul`
  @media ${media.phone} {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 2rem);
    margin-left: -1rem;
    margin-top: -2rem;
  }
`;

export const TextListItem = styled.li`
  @media ${media.maxPhone} {
    &:not(:first-child) {
      margin-top: 2rem;
    }
  }
  @media ${media.phone} {
    width: calc(100% / 3);
    padding: 2rem 1rem 0;
  }
`;

export const TextListNumber = styled(Typography)`
  color: ${({ theme }) => theme.colors.additional.accentGray2};
`;

export const TextListTitle = styled(Typography)`
  margin-top: 1.5rem;
`;

export const TextListDesc = styled.div`
  margin-top: 1.5rem;
  color: ${({ theme }) => theme.colors.additional.accentGray2};
`;
