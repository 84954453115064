import React from 'react';

import * as Styled from './styles';
import { TextListProps } from './types';
import { numberToTwoDigits } from '../../../utils/consts';
import RichText from '../RichText';

const TextList: React.FC<TextListProps> = ({ items }) => (
  <Styled.TextList>
    {items.map(({ key, title, desc }, index) => (
      <Styled.TextListItem {...{ key }}>
        <Styled.TextListNumber variant="textL">
          {numberToTwoDigits(index + 1)}
        </Styled.TextListNumber>
        <Styled.TextListTitle variant="headingS">{title}</Styled.TextListTitle>
        {desc && (
          <Styled.TextListDesc>
            <RichText data={desc} />
          </Styled.TextListDesc>
        )}
      </Styled.TextListItem>
    ))}
  </Styled.TextList>
);

export default TextList;
