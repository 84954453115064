import { graphql } from 'gatsby';
import React from 'react';

import { BLOG_INNER_MAX_WIDTH } from '../../const';
import TextListSectionStatic from '../../TextListSection';
import { inInnerModeType } from '../TextSection/utils';
import { TextListSectionProps } from './types';

const TextListSection: React.VFC<TextListSectionProps> = ({ items, innerMode }) => {
  const isInInnerModeType = inInnerModeType(innerMode);

  return (
    <TextListSectionStatic
      data={{
        innerMode: isInInnerModeType ? innerMode : BLOG_INNER_MAX_WIDTH,
        items: (items ?? []).map((item) => {
          const { title, desc, _key: key } = item ?? {};

          return {
            key: key ?? '',
            title: title ?? '',
            desc: desc ?? undefined,
          };
        }),
      }}
    />
  );
};

export const fragment = graphql`
  fragment TextListSectionFragment on SanityTextListSection {
    _key
    _type
    items {
      _key
      title
      desc: _rawDesc(resolveReferences: { maxDepth: 10 })
    }
    innerMode
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
`;

export default TextListSection;
