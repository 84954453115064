import React from 'react';

import * as Styled from '../TextSection/styles';
import TextList from '../../molecules/TextList';
import { TextListProps } from './types';

const TextListSection: React.FC<TextListProps> = ({ data }) => {
  const { innerMode, items } = data;

  return (
    <Styled.CustomInner {...{ innerMode }}>
      <TextList {...{ items }} />
    </Styled.CustomInner>
  );
};

export default TextListSection;
